// import React, { useEffect } from 'react';

// const PayPalSDK = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://www.paypal.com/sdk/js?client-id=AWW3z9MIpvBcRvIuqgHze8JdAbjizYll_04KlPCWU92D4sVMwn46AuP6ZohuLTph5KqUlpFkWKbX_bZQ';
//     script.async = true;

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return <>
//   </>; // Or any other JSX if needed
// };

// export default PayPalSDK;

import { useEffect } from "react";
const PayPalSDK = () => {
  useEffect(() => {
    // Check if the PayPal script is already added
    if (!window.paypalScriptAdded) {
      const script = document.createElement('script');
      script.src = 'https://www.paypal.com/sdk/js?client-id=AUn0wqj8gSh4P8396HgwJPuyXIuYPrXCVxG4wqG9shDb2CZGoC3lujfWIgoKi3SnIyyK2fzaOtob9aDd';
      script.async = true;

      document.body.appendChild(script);

      // Set a global variable to indicate that the script has been added
      window.paypalScriptAdded = true;
    }

    return () => {
      // Optionally, you can remove the script when the component unmounts
      // document.body.removeChild(script);
    };
  }, []);

  return null; // Or any other JSX if needed
};

export default PayPalSDK;
