import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import CheckOut from './Checkout';
import PayPalSDK from './paypabuttonscript';
// import CheckOut from './Checkout';
// import NewCheckOut from './newcheckout';
// import CheckoutForm from './CheckoutForm';

function App() {
  return (
    <Router>
      <PayPalSDK></PayPalSDK>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/checkout" element={<NewCheckOut />} /> */}
        <Route path="/checkout" element={<CheckOut />} />
      </Routes>
    </Router>
  );
}

export default App;
