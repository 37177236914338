import quotes from "./websiteimages/quotes.png";

export default function QuoteBlock() {
  return (
    <>
      <div id="quoteblock">
        <div id="quote1">
          <h1 id="frontquote">
            <img src={quotes} alt="frontquote"></img>
          </h1>
          <h3>They have the cutest items</h3>
          <h1 id="endquote">
            <img src={quotes} alt="lastquote"></img>
          </h1>
        </div>
        <div id="quote2">
          <h1 id="frontquote">
            <img src={quotes} alt="frontquote"></img>
          </h1>
          <h3>I love this stationery item I got! I will be coming back</h3>
          <h1 id="endquote">
            <img src={quotes} alt="endquote"></img>
          </h1>
        </div>
      </div>
    </>
  );
}
