import { Outlet, Link } from 'react-router-dom';
import "./App.css"

const Layout = () => {
  return (
    <div id='checkoutbtn'>
      <nav>
        <ul>
          <li>
          </li>
          <li>
            <Link to="/checkout">Checkout</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
};

export default Layout;
