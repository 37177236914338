import "./checkoutapge.css"
import PayPalSDK from "./paypabuttonscript"
// import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import PayPalCheckout from './paypalbutton'
export default function CheckOut() {
    return (<>
        <div id="checkoutpage">
        <div id="checkoutdiv">
        <h1 id="checkouttitle">Check Out Page</h1>
        <div id="checkoutbtn">
            <div id="stripestyle">
            {/* <PayPalScriptProvider options={{ "client-id": "AUn0wqj8gSh4P8396HgwJPuyXIuYPrXCVxG4wqG9shDb2CZGoC3lujfWIgoKi3SnIyyK2fzaOtob9aDd" }}>
    <PayPalButtons
        createOrder={(data, actions) => {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        value: '0.01'
                    }
                }]
            });
        }}
        onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
                alert('Transaction completed by ' + details.payer.name.given_name);
            });
        }}
    />
</PayPalScriptProvider> */}
      <PayPalSDK></PayPalSDK>
                <PayPalCheckout></PayPalCheckout>
                <PayPalSDK></PayPalSDK>
            </div>
            </div>
        </div>
        </div>

        </>
    )
}