import React, { useState } from 'react';

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('http://localhost:3001/add-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.text();
      setMessage(data);
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error signing up. Please try again.');
    }
  };

  return (
    <div>
      <h5>Sign Up for Our Email List</h5>
      <form onSubmit={handleSubmit} id='emailinput'>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='email'
          required
        />
        <div id='emailbtn'>
        <button type="submit">Sign Up</button>
        </div>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default EmailForm;
