import React, { useEffect, useState } from 'react';
const PayPalCheckout = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const cartData = localStorage.getItem('localcart');
    const items = cartData ? JSON.parse(cartData) : [];
    setCartItems(items);
  }, []);

  useEffect(() => {
    const calculateTotalAmount = () => {
      let total = 0;
      cartItems.forEach(item => {
        total += item.price * item.quantity;
      });
      return total;
    };
  
    const displayInvoice = () => {
      const modal = document.getElementById('invoice-modal');
      modal.style.display = 'block';
      const invoiceContent = document.getElementById('invoice-content');
      invoiceContent.innerHTML = '';
      cartItems.forEach(item => {
        const itemElement = document.createElement('div');
        itemElement.innerHTML = '<p>' + item.productName + ': $' + (item.price * item.quantity).toFixed(2) + '</p>';
        invoiceContent.appendChild(itemElement);
      });
    };
    if (window.paypal && window.paypal.Buttons) {
      window.paypal.Buttons({
        createOrder: function (data, actions) {
          const totalAmount = calculateTotalAmount();
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: totalAmount.toFixed(2)
              }
            }]
          });
        },


        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            displayInvoice();
            setTimeout(() => {
              localStorage.clear();
              setCartItems([]);
              window.location.href = '/'; 
            }, 4000);
          });
        },

        onError: function (err) {
          console.error('An error occurred during PayPal checkout:', err);
        }
      }).render('#paypal-button-container');
    }
  }, [cartItems]);


  return (
    <div>
      {/* <PayPalSDK></PayPalSDK> //where it would go and work  */}
      <div id="cart">
        {/* Cart items will be dynamically inserted here */}
      </div>
      {/* PayPal Checkout Button */}
      <div id="paypal-button-container"></div>
      {/* Invoice Modal */}
      <div id="invoice-modal" style={{ display: 'none' }}>
        <h2>Invoice</h2>
        <div id="invoice-content">
          {/* Cart items will be dynamically inserted here */}
        </div>
      </div>
    </div>
  );
};

export default PayPalCheckout;


// import React, { useEffect, useState, useRef } from 'react';
// import PayPalSDK from './paypabuttonscript'; // Ensure you have the PayPalSDK component

// const PayPalCheckout = () => {
//   const [cartItems, setCartItems] = useState([]);
//   const paypalButtonContainerRef = useRef(null);
//   const paypalRendered = useRef(false);

//   useEffect(() => {
//     const cartData = localStorage.getItem('localcart');
//     const items = cartData ? JSON.parse(cartData) : [];
//     setCartItems(items);
//   }, []);

//   useEffect(() => {
//     const calculateTotalAmount = () => {
//       let total = 0;
//       cartItems.forEach(item => {
//         total += item.price * item.quantity;
//       });
//       return total;
//     };

//     const displayInvoice = () => {
//       const modal = document.getElementById('invoice-modal');
//       modal.style.display = 'block';
//       const invoiceContent = document.getElementById('invoice-content');
//       invoiceContent.innerHTML = '';
//       cartItems.forEach(item => {
//         const itemElement = document.createElement('div');
//         itemElement.innerHTML = `<p>${item.productName}: $${(item.price * item.quantity).toFixed(2)}</p>`;
//         invoiceContent.appendChild(itemElement);
//       });
//     };

//     if (window.paypal && window.paypal.Buttons && !paypalRendered.current) {
//       window.paypal.Buttons({
//         createOrder: function (data, actions) {
//           const totalAmount = calculateTotalAmount();
//           return actions.order.create({
//             purchase_units: [{
//               amount: {
//                 value: totalAmount.toFixed(2)
//               }
//             }]
//           });
//         },
//         onApprove: function (data, actions) {
//           return actions.order.capture().then(function (details) {
//             displayInvoice();
//             setTimeout(function () {
//               localStorage.clear();
//               setCartItems([]);
//             }, 4000); 
//           });
//         },
//         onError: function (err) {
//           console.error('An error occurred during PayPal checkout:', err);
//         }
//       }).render(paypalButtonContainerRef.current);
//       paypalRendered.current = true;
//     }
//   }, [cartItems]);

//   return (
//     <div>
//       <PayPalSDK /> {/* Ensure the PayPal SDK script is included */}
//       <div id="cart">
//         {/* Cart items will be dynamically inserted here */}
//       </div>
//       {/* PayPal Checkout Button */}
//       <div id="paypal-button-container" ref={paypalButtonContainerRef}></div>
//       {/* Invoice Modal */}
//       <div id="invoice-modal" style={{ display: 'none' }}>
//         <h2>Invoice</h2>
//         <div id="invoice-content">
//           {/* Cart items will be dynamically inserted here */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PayPalCheckout;


