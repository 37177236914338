import "./App.css";
import "./holidaypromo.css";
import Holiday from "./HolidayPromo";
import PromoBanner from "./PromoBanner";
import QuoteBlock from "./QuoteBlock";
import Footer from "./Footer";
import "./fonts.css";
import "./navbarstyle.css";
import "./header.css";
import "./blockquote.css";
import "./footer.css";
import "./productpage.css"
import React, { useEffect } from "react";
import { useState } from 'react';
import Axios from 'axios';
import Layout from "./outlet";
import ReactGA from "react-ga"
import PayPalSDK from "./paypabuttonscript";

//THIS IS GOOGLE ANALYTICS//
ReactGA.initialize('G-QGHFW57E06')

function HomePage() {
  const [list, setList] = React.useState([]);
  console.log(window.location.href.toString())

//   useEffect(()=> {  
//     Axios.get(`/mongoproducts`) // fix this later
//     .then((res)=> setList(res.data));
// }, []); 

// useEffect(() => {
//   const fetchProducts = async () => {
//     console.log("Fetching products..."); // Log when fetch starts
//     try {
//         const res = await Axios.get('http://localhost:4000/mongoproducts');
//         console.log("Products fetched:", res.data); // Log fetched data
//         setList(res.data);
//     } catch (error) {
//         console.error("Error fetching products:", error);
//     }
// };

//   fetchProducts();
// }, []); // OLD CODE THAT WORKS KEEP THIS JUST IN CASE

useEffect(() => {
  const fetchProducts = async () => {
    console.log("Fetching products..."); // Log when fetch starts
    try {
        const res = await Axios.get('/products.json');
        console.log("Products fetched:", res.data); // Log fetched data
        setList(res.data);
    } catch (error) {
        console.error("Error fetching products:", error);
    }
};

  fetchProducts();
}, []); // new code hopedully this works



//ALL SEARCH FUNCTION ITS PERFECT DONT TOUCH GOMEZ 
const [searchInput, setSearchInput] = useState('');
const [searchResults, setSearchResults] = useState([]);
const [resultDisplay, setResultDisplay] = useState(false);

const loadedcart = JSON.parse(localStorage.getItem('localcart') || "[]")

const [cart, setCart] = useState(loadedcart);


useEffect(() => {
  localStorage.setItem("localcart", JSON.stringify(cart));
}, [cart])



const handleSearch = (e) => {
  const inputValue = e.target.value.toLowerCase();
  setSearchInput(inputValue);
if (e.target.value.toLowerCase().length > 0) {
  const filteredResults = list.filter(product => 
    product.productName.toLowerCase().includes(inputValue)
  );
  setSearchResults(filteredResults);
  setResultDisplay(true);
  setProductpageDis(false)

 } 
 else {
    setResultDisplay(false);
  }
};
//DONT TOUCH GOMEZ

//NAV BAR BUTTON SEARCH
const handleClick = (input) => {
  const navfiltered = list.filter((product) => product.productName.toLowerCase().includes(input))
  setSearchResults(navfiltered)
  setResultDisplay(true)
  };
//AGAIN DONT TOUCH GOMEZ

// HOME && CLEAR SEARCH BUTTON
const mainmenu = () => {
  setResultDisplay(false)
  setSearchInput('');
  setSearchResults([]);
  setProductpageDis(false);
  
}

// const addToCart = (product) => {
//   // Update the cart state by adding the selected product
//   setCart([...cart, { ...product, quantity: 1 }]);
  
  
// };




const addToCart = (productToAdd) => {
  // Check if the product already exists in the cart
  const existingProductIndex = cart.findIndex(
    (product) => product._id === productToAdd._id
  );

  if (existingProductIndex !== -1) {
    // Product already exists in the cart, check if adding 1 exceeds available quantity
    const updatedCart = cart.map((product, index) =>
      index === existingProductIndex && product.quantity < productToAdd.quantity
        ? { ...product, quantity: product.quantity + 1 }
        : product
    );
    setCart(updatedCart);
  } else {
    // Product doesn't exist in the cart, add it with quantity 1
    setCart([...cart, { ...productToAdd, quantity: 1 }]);
  }
};

//UI OF SEARCHED PRODUCT
const searchDisplayBox = searchResults.map((product, i) => {
  return (
<div id="productblock">
    <div id="productimg" onClick={() => handleproductdis(product)}>
      <img src={product.imageurl} alt=" idk yet" width={304} height={253}></img>
      </div>
  <div id="product-controls">
    <button onClick={() => {decreaseQuantity(product._id)}}>-</button>
    <div id="productdisc">
      <p id={i+'pname'}>{product.productName}</p>
      <p id={i+'price'} value={product.price}>{product.price}</p>
      <p id={i+'quantity'}>Remaining: {product.quantity}</p>
    </div>
    <button onClick={() => {addToCart(product)}}>+</button>
  </div>
  </div> 
  )
})

////////



const [searchtoggle, setSearchToggle] = useState(false)

const [buttontoggle, setButtonToggle] = useState(false);




const [showcart, SetShowCart] = useState(false);

//Checkout Cart List
const [productpagenumber, setProductPageNumber] = useState();
const [productpagedivdisp, setProductpageDis] = useState(false);

const handleproductdis = (product) => {
  setProductpageDis(true)
  setProductPageNumber(product.productName)
  setResultDisplay(true)
}


// function addTocart(product) {
//   cartlist.push(product)

//     localStorage.setItem('localcart', JSON.stringify(cartlist))

//     console.log(cartlist)
// }




let val = list.map((product, i)=>{


//   let searchinput = document.getElementById('search2').value.toLowerCase()
  

//   // if (product.productName.toLowerCase() === document.getElementById('search').value.toLowerCase().includes(searchinput.value)) {
//   if (product.productName.toLowerCase().includes(searchinput)) {

// return <div id="productblock">
//     <div id="productimg">
//       <img src={product.imageurl} alt=" idk yet" width={304} height={253}></img>
//       </div>
//   <div id="product-controls">
//     <button>-</button>
//     <div id="productdisc">
//       <p id={i+'pname'}>{product.productName}</p>
//       <p id={i+'price'} value={product.price}>{product.price}</p>
//       <p id={i+'quantity'}>Remaining: {product.quantity}</p>
//     </div>
//     <button onClick={addToCart}>+</button>
//   </div>
//   </div> 
//   }


  
  

  return <div id="productblock" style={{display: searchtoggle? 'none' : 'block'}}>
    <div id="productimg">
      <img src={product.imageurl} alt=" idk yet" width={304} height={253} onClick={() => handleproductdis(product)}></img>
      </div>
  <div id="product-controls">
    <button onClick={() => decreaseQuantity(product._id)}>-</button>
    <div id="productdisc">
      <p id={i+'pname'}>{product.productName}</p>
      <p id={i+'price'}>{product.price}</p>
      <p id={i+'quantity'}>Remaining: {product.quantity}</p>
    </div>
    <button onClick={() => addToCart(product)}>+</button>
  </div>
  </div>      
});

let Productpage = list.map((product, i) => {
  return <div id={i+`productbox`} style={{display: productpagenumber === product.productName && productpagedivdisp === true? 'block': 'none'}}>
  <div id={"productPage"}>
        <div id="productpageimg">
      <img src={product.imageurl} alt=" idk yet" width={444} height={393}></img>
      </div>
      <div id="productpagename">
        {/* <button onClick={() => {setProductpageDis(false)} }>x</button> */}
      <h1 id='pname'>{product.productName}</h1>
      <p id='categ'>Category: {product.categ}</p>
      <p id='price'>{product.price} USD</p>
      <p id='desc'>{product.desc}</p>
      <div id="productpagecontrols">
    <button onClick={() => addToCart(product)}>Add to cart</button>
  </div>
  </div>
  </div>
  </div>
})






// const addToCart = (product) => {
//   // Update the cart state by adding the selected product
//   setCart([...cart, { ...product, quantity: 1 }]);
// };

// Function to handle removing items from the cart
const removeFromCart = (productToRemove) => {
  // Filter out the product to remove from the cart
  const updatedCart = cart.filter(
    (product) => product._id !== productToRemove._id
  );
  // Update the cart state with the filtered cart
  setCart(updatedCart);
};

// Function to handle increasing the quantity of an item in the cart
// const increaseQuantity = (productId) => {
//   // Find the product in the cart
//   const updatedCart = cart.map((product) => {
//     if (product._id === productId) {
//       console.log(localStorage.getItem('localcart'))
//       // Increase the quantity by 1
//       return { ...product, quantity: product.quantity + 1 };
//     }
//     return product;

//   });
//   // Update the cart state with the updated quantity
//   setCart(updatedCart);
// };

// Function to handle decreasing the quantity of an item in the cart
const decreaseQuantity = (productId) => {
  // Find the product in the cart
  const updatedCart = cart.map((product) => {
    if (product._id === productId && product.quantity > 1) {


      return { ...product, quantity: product.quantity - 1 };
    }
    return product;
  });


  // Update the cart state with the updated quantity
  setCart(updatedCart);
};

// Render the list of products
// const productList = list.map((product, index) => (
//   <div key={index}>
//     <p>{product.productName}</p>
//     <p>${product.price}</p>
//     <button onClick={() => addToCart(product)}>Add to Cart</button>
//   </div>
// ));



const cartItems = cart.map((item, index) => (



  
  <>
  <div key={index}>
    <img src={item.imageurl} height={"100px"} width={"100px"} alt="cartimages"></img>
    <p>{item.productName}</p>
    <p>${(item.price * item.quantity).toFixed(2)}</p>
    <p>Quantity: {item.quantity}</p>
    {/* <button onClick={() => increaseQuantity(item._id)}>+</button> */}
    {/* <button onClick={() => decreaseQuantity(item._id)}>-</button> */}
    <button onClick={() => removeFromCart(item)}>remove</button>
  </div>


  </>
));




const calculateCartTotal = () => {
  let total = 0;
  cart.forEach(item => {
    total += item.price * item.quantity;
  });
  return (
  <>
  <h3>Total: {total.toFixed(2)}</h3>
  </>
  )
};





const localCartData = localStorage.getItem('localcart');
const objdata = localCartData ? JSON.parse(localCartData) : [];


// const cartItems = objdata.map((item, index) => (
//   <div key={index}>
//     <img src={item.imageurl} style={{width: '100px', height: "100px"}} alt="cartitemsimage"></img>
//     <p>{item.productName}</p>
//     <p>${item.price}</p>
//   </div>
// ));

// let displaysearchresult = searchResults.map(product => (
//   <li key={product.id}>{product.productName}</li>
// ))
  // Retrieve cart data from local storage




  return (
    <div>
      <PayPalSDK></PayPalSDK>
      <div className="App">
      <PayPalSDK></PayPalSDK>

        <PromoBanner></PromoBanner>

        <div id="content">
          {/* <Header></Header> */}
          <>
      <div id="pagename">
        <h1 onClick={mainmenu}>THE STATION FOR STATIONERY SUPPLIES</h1>
      </div>

      <div id="searchbar">
        <div id="searchbarbox">
          <input
          id="search2"
            type="search"
            placeholder="Looking for something specific..."
            value={searchInput}
            onChange={handleSearch}
          ></input>
          <button id="searchbtn" onClick={() => {setSearchToggle(true) && setButtonToggle(false)}}>
            <img
              id="searchimg"
              width="20"
              height="20"
              src="https://img.icons8.com/external-outline-juicy-fish/60/external-magnifying-contact-us-outline-outline-juicy-fish.png"
              alt="external-magnifying-contact-us-outline-outline-juicy-fish"
            ></img>
          </button>

{/*test*/}
          {/* <div>
    Render product list
    <div>{productList}</div>

    Render cart
    <div>
      <h2>Shopping Cart</h2>
      {cartItems.length > 0 ? (
        <div>{cartItems}</div>
      ) : (
        <p>Your cart is empty</p>
      )}
    </div>
  </div> */}

        </div>



        <div id="cartbtnbox">
          <button id="cartbtn" onClick={() => [
            SetShowCart(!showcart)
          ]}>
            <img
              id="cart"
              width="32"
              height="32"
              src="https://img.icons8.com/windows/32/shopping-cart.png"
              alt="shopping-cart"
            />
          </button>
        </div>

      </div>

      <div>
    {objdata.length !== 0 && (
      <div id="shoppingcart" style={{display: showcart ? 'block' : 'none'}}>
        <h4>SHOPPING CART</h4>
        <div id="cartcontent">
          {cartItems}
        </div>
        <div id="cartTotalDiv">
          <p>{calculateCartTotal()}</p>
          <Layout></Layout>
          </div>
      </div>
    )}
  </div>
      <div id="navbar">
        <ul>
          <li>
            <button id="myBtn1" value="pen" onClick={() => handleClick('pens')}>Pens</button>
          </li>
          <li>
            <button id="myBtn" value="pencil" onClick={() => handleClick('pencil')}>Pencils</button>
          </li>
          <li>
            <button id="myBtn" value="brush" onClick={() => handleClick('brushes')}>Brushes</button>
          </li>
          <li>
            <button id="myBtn" value="noteook" onClick={() => handleClick('notebook')}>Notebooks</button>
          </li>
          <li>
            <button id="myBtn" value="about" onClick={() => handleClick('about')}>About Us</button>
          </li>
        </ul>
      </div>
    </>
          <div id="displaytest" style={{display: resultDisplay? 'none': 'block' && buttontoggle? 'none' : 'block' }}>

          <Holiday></Holiday>
      <h1 id="productrowtitle">note to self: buy some notes</h1>
          <div id="productrow">
          {val.slice(0, 3)}
          </div>
          <QuoteBlock></QuoteBlock>
      <h1 id="productrowtitle">write with something new</h1>
          <div id="productrow">
          {val.slice(-3)}
          </div>
          </div>
        <div id="productrow" style={{display: resultDisplay? 'none': 'none', justifyContent: "space-evenly"}}>
        <div>
        <button id="backbtn" onClick={mainmenu}>clear search</button>
        </div>
        {val}
        <div style={{display: resultDisplay? 'block' : 'none'}}>

      </div>
        </div>

        <div id="productpage" style={{display: searchtoggle? 'none' : 'flex', justifyContent: "space-evenly"}}>
          <div>

        </div>
        </div>
        <div id="productrow" style={{display: resultDisplay? 'flex' : 'none' }}>
        <button id="backbtn" onClick={mainmenu}> go back</button>
          {searchDisplayBox}
          <div id="noresult">
          </div>
          </div>
          

        </div>
        <div id="productpagediv" style={{display: productpagedivdisp? 'block': 'none' && resultDisplay? "none": "block"}}>
          {Productpage}
          </div>


        <Footer></Footer>
      </div>
      <PayPalSDK></PayPalSDK>
    </div>

  );
}

export default HomePage;
