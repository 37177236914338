import socialIconOnepng from "./socialmediaicon/social.png"
import socialIconDuopng from "./socialmediaicon/socialdual.png"
import websitepng from "./socialmediaicon/website.png" 
import EmailForm from "./emailform";

export default function Footer() {
  return (
    <div id="footer">
      <div id="footerbox">
        <div id="contact">
          <h5>THE STATION FOR STATIONERY SUPPLIES</h5>

          <div id="social-media">

            <div id="social-mediaOne">
            <img alt="instagramicon" src={socialIconOnepng}></img> <p>@stationery.supply</p>
            </div>
            <div id="social-mediaTwo">
            <img alt="xsocialicon" src={socialIconDuopng}></img> <p>@stationerysupplies</p>
            </div>
            <div id="social-mediaThree">
            <img alt="emailicon" src={websitepng}></img> <p>stationerysupplies@gmail.com</p>
            </div>
          </div>
        </div>
        <div id="email-form">
          {/* <h5>Join our emailing list</h5>
          <form id="emailinput">
            <input type="email" placeholder="email"></input>
            <div id="emailbtn">
              <button>join</button>
            </div>
          </form>
          THIS IS OLD COLD WITH NO ACTUAL FUNCTION 
          */}


          <EmailForm></EmailForm>

        </div>
      </div>
    </div>
  );
}
