import holiday from "./websiteimages/holiday.jpg";
import "./holidaypromo.css"
import { useState } from "react";

export default function Holiday() {

  const [showmore, SetShowMore] = useState(false);

  return (
    <>
    <div id="holidaypromo">
      <div id="holidaydisc">
        <div id="holidaytext">
          <h1>Mother's Day Special</h1>
          <p>
            With Mother's Day just around the corner, why not surprise your mom
            with a gift she'll never forget? Our Mother's Day-themed notebooks
            are just the thing to brighten up her day and show her how much you
            care.
            <div id="holidaybtn">
              <button id="explorebtn" onClick={() => {
                SetShowMore(!showmore)
              }}>Explore</button>
            </div>
          </p>
        </div>
      </div>
      <div id="holidayimg">
        <img src={holiday} alt="holiday" ></img>
      </div>
    </div>
    <div id="moreholidayinfo" style={{display: showmore? 'flex' : 'none'}}> Explore lorem ipsum</div>
    </>
  );
}

// width="442.12px" height="385px"
